export const WhyChoosUs = [
  {
    header: "24/7 Availability",
    img: "Images/WhyUs/everyday-service.avif",
    desc: "Experience round-the-clock convenience with our 24/7 service, ensuring assistance at any hour,day or night. Our commitment to availability means you can rely on us whenever you need assistance.",
  },
  {
    header: "Professional Drivers",
    img: "Images/WhyUs/driver.jpg",
    desc: "Your journey is in capable hands with our professional cab drivers, ensuring a smooth and enjoyable experience. With our dedicated team of professional cab drivers, you can trust that you are in good hands from pickup to drop-off.",
  },
  {
    header: "Well-Maintained Vehicles",
    img: "Images/WhyUs/maintained.jpg",
    desc: "We understand the importance of a clean and well-maintained cab.Thats why we invest in regular cleaning and maintenance to ensure a superior experience for our passengers. Stepping inside reveals a pristine environment.",
  },
];

export const CarImageArray = [
  {
    title: "24/7 Availability",
    img: "Images/Gallary/1.jpeg",
  },
  {
    title: "24/7 Availability",
    img: "Images/Gallary/2.jpeg",
  },
  {
    title: "24/7 Availability",
    img: "Images/Gallary/3.jpeg",
  },
  {
    title: "24/7 Availability",
    img: "Images/Gallary/4.jpeg",
  },
  {
    title: "24/7 Availability",
    img: "Images/Gallary/5.jpeg",
  },
  {
    title: "24/7 Availability",
    img: "Images/Gallary/6.jpeg",
  },
  {
    title: "24/7 Availability",
    img: "Images/Gallary/7.jpeg",
  },
  {
    title: "24/7 Availability",
    img: "Images/Gallary/8.jpeg",
  },
  {
    title: "24/7 Availability",
    img: "Images/Gallary/9.jpeg",
  },
  {
    title: "24/7 Availability",
    img: "Images/Gallary/10.jpeg",
  },
  {
    title: "24/7 Availability",
    img: "Images/Gallary/11.jpeg",
  },
  {
    title: "24/7 Availability",
    img: "Images/Gallary/12.jpeg",
  },
  {
    title: "24/7 Availability",
    img: "Images/Gallary/13.jpeg",
  },
  {
    title: "24/7 Availability",
    img: "Images/Gallary/14.jpeg",
  },
];

export const SaftyMeasuresArray = [
  {
    id: 1,
    title: "Driver Screening",
    desc: "Conduct thorough background checks and screenings for all drivers, including verification of driving records, criminal history, and professional credentials.",
  },
  {
    id: 2,
    title: "Vehicle Maintenance",
    desc: "Regularly inspect and maintain all vehicles to ensure they are in optimal condition for safe operation. This includes checking brakes, tires, lights, and other essential components.",
  },
  {
    id: 3,
    title: "Sanitization Protocols",
    desc: "Implement strict sanitization protocols to disinfect vehicles before and after each use. Pay special attention to high-touch surfaces such as door handles, seat belts, and window controls.",
  },
  {
    id: 4,
    title: "Customer Feedback",
    desc: "Encourage passengers to provide feedback on their ride experience, including safety concerns. Use this feedback to continually improve safety measures and address any issues promptly.",
  },
  {
    id: 5,
    title: "Emergency Assistance",
    desc: "Equip vehicles with emergency kits containing essential items such as first aid supplies, flashlights, and emergency contact information. Ensure drivers are trained to respond effectively to emergencies.",
  },
  {
    id: 6,
    title: "Compliance with Regulations",
    desc: "Ensure compliance with local regulations and industry standards related to passenger safety, vehicle maintenance, insurance coverage, and licensing requirements.",
  },
];

export const OurServices = [
  {
    header: "Mumbai To Pune",
    img: "Images/Services/pune.jpg",
    desc: "Travel in style and comfort with our premium cab service. From Mumbai's bustling streets to Pune's vibrant cityscape, let us take you there in luxury.",
  },
  {
    header: "Pune To Mumbai",
    img: "Images/Services/Gateway-of-India.jpg",
    desc: "Make your Pune to Mumbai trip memorable for all the right reasons. With our reliable cab service, you can trust us to get you there on time, every time.",
  },
  {
    header: "Airport Pickup",
    img: "Images/Services/airport.jpg",
    desc: "In addition to our city-to-city trips, we provide efficient airport pick-up services, ensuring prompt arrivals and departures. Book our cab to pick you on time.",
  },
  {
    header: "Pune / Mumbai   To Shirdi",
    img: "Images/Services/shirdi.jpg",
    desc: "Whether you're starting your journey from the bustling city of Pune or the vibrant metropolis of Mumbai, our cab services offer seamless transportation to the spiritual destination of Shirdi. No matter your point of origin, we ensure a comfortable and convenient ride to Shirdi, catering to travelers from both Pune and Mumbai.",
  },
  {
    header: "Pune / Mumbai To Alibaug",
    img: "Images/Services/alibag.jpg",
    desc: "Experience the coastal charm of Alibaug with our exclusive cab services connecting Pune or Mumbai to this picturesque destination. Our flexible pick-up and drop-off options cater to your convenience, making it easy for you to explore the sandy beaches and historic landmarks of Alibaug at your own pace. Choose us as your preferred travel partner.",
  },
  {
    header: "Pune / Mumbai To Outstation",
    img: "Images/Services/outstation.jpg",
    desc: "Discover seamless travel experiences with our outstation cab services, connecting you from Pune or Mumbai to destinations far and wide. Trust us to be your trusted travel partner, providing dependable transportation solutions from Pune/Mumbai to a variety of outstation destinations, ensuring memorable journeys every step of the way.",
  },
];

export const bgImages=[
    {
        img:'Images/Background/1.jpg',
        alt:''
    },
    {
        img:'Images/Background/2.jpg',
        alt:''
    },
    {
        img:'Images/Background/3.jpg',
        alt:''
    },
    {
        img:'Images/Background/4.jpg',
        alt:''
    },
    {
        img:'Images/Background/5.jpg',
        alt:''
    },
    {
        img:'Images/Background/6.jpg',
        alt:''
    },
    {
        img:'Images/Background/7.jpg',
        alt:''
    },
    {
        img:'Images/Background/8.jpg',
        alt:''
    },
    {
        img:'Images/Background/9.jpg',
        alt:''
    },
    {
        img:'Images/Background/10.jpg',
        alt:''
    },
    {
        img:'Images/Background/11.jpg',
        alt:''
    },
    {
        img:'Images/Background/12.jpg',
        alt:''
    },
]

export const CarLists = [
  {
    header: "Hatchback (Swift, Wagon R Or Similar)",
    img: "Images/Cars/hatchback.jpg",
    alt: "BB Cabs Hatchback Car",
    type:"Hatchback"
  },
  {
    header: "Sedan (Verna, Dzire Or Similer)",
    img: "Images/Cars/sedan2.jpg",
    alt: "BB Cabs Sedan Car",
    type:"Sedan"
  },
  {
    header: "Premium Sedan (Ciaz, Slavia Or Similar)",
    img: "Images/Cars/premium-sedan.jpg",
    alt: "BB Cabs Premium Sedan Car",
    type:"Premium Sedan"
  },
  {
    header: "SUV (Creta, Ertiga or Similar)",
    img: "Images/Cars/suv.jpg",
    alt: "BB Cabs Suv Car",
    type:"SUV"
  },
  {
    header: "Premium SUV (Innova Crysta or Similar)",
    img: "Images/Cars/premium-suv.jpg",
    alt: "BB Cabs Premium Suv Car",
    type:"Premium SUV"
  }
  
];