import Carousel from "react-material-ui-carousel";
import { makeStyles } from "@material-ui/core";
import PropTypes from 'prop-types';
import {bgImages} from '../../mock/db'
import clsx from 'clsx';



const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
    },
    home__carousel:{
      height:650,
      [theme.breakpoints.down('md')]: {
        height:400,
      },
      [theme.breakpoints.down('sm')]: {
        height:200,
      }
    },
    home__image :{
      width: '100%',
      maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))'
    }
  }));

function BannerCarousel({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
    className={clsx(classes.root, className)}
    {...rest}
  >
      <div className="home__container">
        <Carousel
          autoPlay={true}
          className={classes.home__carousel}
          navButtonsAlwaysVisible={true}
          navButtonsAlwaysInvisible={false}
        >
          {bgImages.map((item, i) => (
            <img
              key={i}
              src={item.img}
              alt={item.alt}
              className={classes.home__image}
            />
          ))}
        </Carousel>
      </div>
      </div>
  )
}

BannerCarousel.propTypes = {
  className: PropTypes.string
};

export default BannerCarousel;