import { makeStyles, ThemeProvider } from "@material-ui/core";
import HomeView from "../src/Views/HomeView";
import CarList from "../src/Views/CarList";
import BookCars from "../src/Views/CarList/CarListItems";
import MainLayout from "../src/layouts/MainLayout";
import { createThemes } from "../src/theme";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

const useStyles = makeStyles(() => ({
  root: {},
}));

function App() {
  //UNICORN || ONE_DARK || LIGHT
  const settings = {
    direction: "ltr",
    responsiveFontSizes: true,
    theme: "UNICORN",
  };

  const classes = useStyles();
  return (
    // <ThemeProvider theme={createThemes(settings)}>
    // <MainLayout>
    //   <HomeView/>
    // </MainLayout>
    // </ThemeProvider>
    <ThemeProvider theme={createThemes(settings)}>
      <BrowserRouter>
      <MainLayout>
        <Routes>
          <Route path="/" element={<HomeView />}/>
          <Route path="CarList" element={<CarList />} />
          <Route path="CarLists" element={<BookCars />} />
        </Routes>
        </MainLayout>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
