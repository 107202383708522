import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grid,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import ImageCard from '../../components/ImageCard'
import {OurServices} from '../../mock/db'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 40,
    paddingBottom: 50
  },
  gridContainer: {
    padding: "40px 0 0 0",    
  }
}));

function WhyUs({ className, ...rest }) {
  
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"          
        >
          Our Services
        </Typography>
        <Grid
          container
          spacing={4}
          align="center"
          className={clsx(classes.gridContainer, className)}
        >
          {OurServices && OurServices.map((item,index)=>{
            return(
            <Grid item xs={12} sm={6} md={4}>
            <ImageCard 
              header={item.header} 
              image={item.img} 
              desc={item.desc}
              action="Book Now"
              />
            </Grid>)
          })}
        </Grid>
      </Container>
    </div>
  );
}

WhyUs.propTypes = {
  className: PropTypes.string
};

export default WhyUs;
