import React from "react";
import { makeStyles } from "@material-ui/core";
import Page from "../../components/Page";
//import Banner from "./Banner";
import SafetyMeasures from "./SafetyMeasures";
import OurServices from "./OurServices";
import WhyUs from "./WhyUs";


import BannerCarousel from "./BannerCarousel";

const useStyles = makeStyles(() => ({
  root: {},
  
}));

function HomeView() {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="BB Cabs | Pune to Mumbai Car Rentals">
      <BannerCarousel />
      <OurServices />
      <SafetyMeasures />
      <WhyUs />    
    </Page>
  );
}

export default HomeView;
