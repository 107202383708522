import React from 'react';

function Logo(props) {
  return (
    <img
      alt="Logo"
      src="Images/Logo/BBLogo.png"
      {...props}
      height="64px"
      width="64px"
    />
  );
}

export default Logo;
