import React from "react";
import { Grid, Container, makeStyles,IconButton } from "@material-ui/core";
import Page from "../../components/Page";
import { CarLists } from "../../mock/db";
import clsx from "clsx";
import {Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Button
} from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 20,
    paddingBottom: 50,
  },
  gridContainer: {
    padding: "40px 0 0 0",
  },
  Card:{
    display:'flex'
  },
  CardMedia:{
    height:200,
    width:'370px !important',
    paddingLeft:theme.spacing(1) 
  },
  CardContent:{
    width:'400px !important',
    paddingLeft:theme.spacing(1) 
  },
  call: {
    position: "fixed",
    width: "60px",
    height: "60px",
    top: "40px",
    backgroundColor: "rgb(116, 87, 178);",
    borderRadius: "50px",
    textAlign: "center",
    boxShadow: "2px 2px 3px #898282",
    border: "none",
    marginLeft: "100px",
    marginTop:"50px",
    zIndex:1,
  },
  icon: {
    color: "#FFF",
    marginLeft: "18px",
    marginTop: "18px",
  },
}));

function CarList({ className, ...rest }) {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="BB Cabs | Pune to Mumbai Car Rentals">
      <div className={clsx(classes.root, className)} {...rest}>
        <Container maxWidth="lg">
        <Typography
          variant="h4"
          align="center"
          color="white"          
        >
          Select your car
        </Typography>
          <Grid
            container
            spacing={4}
            align="center"
            className={clsx(classes.gridContainer, className)}
          >
            {CarLists &&
              CarLists.map((item, index) => {
                return (
                  <Grid item xs={12} sm={12} md={12}>
                    <Card
                      sx={{ maxWidth: 1000,height:200 }}
                      className={clsx(classes.Card, className)}
                      {...rest}
                    >
                      <CardMedia
                        className={clsx(classes.CardMedia, className)}
                        component="img"
                        image={item.img}
                        alt={item.alt}
                      />
                      <CardContent className={clsx(classes.CardContent, className)}>
                        <Typography variant="h5" color="text.primary">
                          {item.header}
                        </Typography>
                      </CardContent>                      
                        <CardActions>
                          <Button
                            size="medium"
                            variant="contained"
                            component="a"
                            href={`https://wa.me/8801021008?text=Hi BB Cabs, I want to book a ${item.type}.`}
                            style={{
                              marginLeft: "auto",
                              position:"left",
                              backgroundColor: "#a67dff",
                            }}
                          >
                            Enquire Now
                          </Button>
                        </CardActions>                      
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      </div>
      <Link to="/">
      <IconButton
        class={classes.call}
        aria-label="Call"
        component="a"
        href="tel:8801021008"
      >
        <ArrowBackIcon className={classes.icon} />
      </IconButton>
      </Link>
    </Page>
  );
}

export default CarList;
