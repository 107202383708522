import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Grid,
  Container,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { CarLists } from "../../mock/db";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 40,
    paddingBottom: 50,
  },
  gridContainer: {
    padding: "40px 0 0 0",
  },
  backButton: {
    position: "fixed",
    width: "60px",
    height: "60px",
    top: "40px",
    backgroundColor: "rgb(116, 87, 178);",
    borderRadius: "50px",
    textAlign: "center",
    boxShadow: "2px 2px 3px #898282",
    border: "none",
    marginLeft: "20px",
    marginTop: "50px",
    zIndex: 1,
  },
  icon: {
    color: "#FFF",
    marginLeft: "18px",
    marginTop: "18px",
  },
}));

function BookCars({ className, ...rest }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography variant="h1" align="center" color="textPrimary">
          Select your car
        </Typography>
        <Grid
          container
          spacing={4}
          align="center"
          className={clsx(classes.gridContainer, className)}
        >
          {CarLists &&
            CarLists.map((item, index) => {
              return (
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{ maxWidth: 345 }}
                    className={clsx(classes.root, className)}
                    {...rest}
                  >
                    <CardMedia component="img" height="194" image={item.img} />
                    <CardContent>
                      <Typography variant="h4">{item.header}</Typography>
                    </CardContent>
                    <CardActions style={{ display: "block" }}>
                      <Button
                        size="medium"
                        variant="contained"
                        component="a"
                        href={`https://wa.me/8801021008?text=Hi BB Cabs, I want to book a ${item.type}.`}
                        style={{
                          marginLeft: "auto",
                          position: "left",
                          backgroundColor: "#a67dff",
                        }}
                      >
                        Enquire Now
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Container>
      <Link to="/">
        <IconButton
          class={classes.backButton}
          aria-label="Call"
          component="a"
          href="tel:8801021008"
        >
          <ArrowBackIcon className={classes.icon} />
        </IconButton>
      </Link>
    </div>
  );
}

BookCars.propTypes = {
  className: PropTypes.string,
};

export default BookCars;
