import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  contactLabels: {
    color: "white",
    marginLeft: "10px",
  },
  contactLabel: {
    color: "white",
    marginLeft: "60px",
  },
  icon: {
    color: theme.palette.common.white,
  },
}));

function Footer({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Box>
          <Grid
            container
            spacing={4}
            className={clsx(classes.gridContainer, className)}
          >
            <Grid item xs={12} md={8}>
              <Box>
                <Typography variant="h2" color="textPrimary">
                  BB Cabs
                </Typography>
                <Box style={{ color: "white", marginTop: "10px" }}>
                Introducing BB Cabs, the premier transportation service established in 2023, 
                specializing in seamless travel experiences between Pune and Mumbai. 
                Our reliable and comfortable cabs offer convenient travel options for both directions, 
                ensuring a hassle-free journey every time. In addition to our city-to-city trips, 
                we provide efficient airport pick-up services, ensuring prompt arrivals and departures. 
                Explore the beauty of Maharashtra with our customized tour packages, 
                designed to cater to your preferences and interests. 
                Trust BB Cabs for your transportation needs and embark on unforgettable journeys across Pune and Mumbai.
                </Box>
                <Grid item xs={12} sm={6}>
                  <IconButton aria-label="Facebook">
                    <WhatsAppIcon className={classes.icon} />
                  </IconButton>
                  <IconButton aria-label="Twitter">
                    <FacebookIcon className={classes.icon} />
                  </IconButton>
                  <IconButton aria-label="Instagram">
                    <InstagramIcon className={classes.icon} />
                  </IconButton>
                  <IconButton aria-label="LinkedIn">
                    <TwitterIcon className={classes.icon} />
                  </IconButton>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <Typography variant="h2" color="textPrimary">
                  Contact Us
                </Typography>
                <Divider />
                <Box>
                  <IconButton aria-label="LinkedIn">
                    <PlaceIcon className={classes.icon} />
                  </IconButton>
                  <b className={classes.contactLabels}>503, A1 Bhairavgad, Sector 12 </b>
                  <Box><b className={classes.contactLabel}>Bhosari, Pune 411026. </b></Box>
                </Box>
                <Divider />
                <Box>
                  <IconButton aria-label="LinkedIn">
                    <PhoneIcon className={classes.icon} />
                  </IconButton>
                  <b className={classes.contactLabels}>+91 7559221008 </b>
                </Box>
                <Divider />
                <Box>
                  <IconButton aria-label="LinkedIn">
                    <PhoneIcon className={classes.icon} />
                  </IconButton>
                  <b className={classes.contactLabels}>+91 8801021008 </b>                  
                </Box>
                <Divider />
                <Box>
                  <IconButton aria-label="LinkedIn">
                    <EmailIcon className={classes.icon} />
                  </IconButton>
                  <b className={classes.contactLabels}>bbcabs1008@gmail.com</b>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
