import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles,IconButton } from '@material-ui/core';
import TopBar from './TopBar';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  }, 
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  whatsapp: {
    position: "fixed",
    width: "60px",
    height: "60px",
    bottom: "40px",
    right: "15px",
    backgroundColor: "#25d366",
    borderRadius: "50px",
    textAlign: "center",
    boxShadow: "2px 2px 3px #898282",
    border: "none",
    marginRight: "10px",
    zIndex:1,
  },
  call: {
    position: "fixed",
    width: "60px",
    height: "60px",
    bottom: "40px",
    backgroundColor: "rgb(116, 87, 178);",
    borderRadius: "50px",
    textAlign: "center",
    boxShadow: "2px 2px 3px #898282",
    border: "none",
    marginLeft: "20px",
    zIndex:1,
  },
  icon: {
    color: "#FFF",
    marginLeft: "18px",
    marginTop: "18px",
  },
}));

function MainLayout({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
      <Footer/>

      <IconButton
        class={classes.call}
        aria-label="Call"
        component="a"
        href="tel:8801021008"
      >
        <PhoneIcon className={classes.icon} />
      </IconButton>
      <IconButton
        class={classes.whatsapp}
        aria-label="WhatsApp"
        component="a"
        href="https://wa.me/8801021008?text=Hi BB Cabs, I want to book a cab."
      >
        <WhatsAppIcon className={classes.icon} />
      </IconButton>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any
};

export default MainLayout;
