import * as React from "react";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@mui/material";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  button:{
    
  }
}));

function ImageCard(props, { className, ...rest }) {
  const classes = useStyles();
  return (
    <Card
      sx={{ maxWidth: 345 }}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title={props.header} />
      <CardMedia component="img" height="194" image={props.image} />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {props.desc}
        </Typography>
      </CardContent>
      {props.action ? (
        <CardActions>
          <Link to="/carlists" style={{marginLeft: "auto",}}>
            <Button
              size="small"
              variant="contained"
              style={{                
                backgroundColor: "#a67dff",
                color: "text.secondary",
              }}
            >
              {props.action}
            </Button>
          </Link>
        </CardActions>
      ) : (
        ""
      )}
    </Card>
  );
}

export default ImageCard;
