import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import {SaftyMeasuresArray} from '../../mock/db'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 60,
    paddingBottom: 60
  },
  gridContainer: {
    padding: "50px 0 0 0",    
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

function SafetyMeasures({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        {/* <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
        >
          Explore Devias Kit Pro
        </Typography> */}
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          Safety Measures
        </Typography>
        <Box >
          <Grid
            container
            spacing={4}
            className={clsx(classes.gridContainer, className)}
          >
            {SaftyMeasuresArray && SaftyMeasuresArray.map((item,index)=>{
                return(
                  <Grid
                  item
                  xs={12}
                  md={4}
                >
                  <Box display="flex">
                    <Avatar className={classes.avatar}>
                      {item.id}
                    </Avatar>
                    <Box ml={2}>
                      <Typography
                        variant="h4"
                        gutterBottom
                        color="textPrimary"
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                      >
                        {item.desc}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                
                )
              })}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

SafetyMeasures.propTypes = {
  className: PropTypes.string
};

export default SafetyMeasures;
