import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import Logo from "../../components/Logo";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    height: 64,
  },
  logo: {
    marginRight: theme.spacing(1),
  },
  divider: {
    width: 1,
    height: 25,
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

function TopBar({ className, ...rest }) {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <Logo className={classes.logo} />
        <Box flexGrow={1} />
        <Box position="right">
          <Button
            color="secondary"
            component="a"
            href="https://wa.me/8801021008?text=Hi BB Cabs, I want to book a cab."
            variant="contained"
            size="small"
          >
            <WhatsAppIcon />
            <Divider className={classes.divider} />
            Enquire
          </Button>
          <Button
            className={classes.button}
            color="secondary"
            component="a"
            href="tel:8801021008"
            variant="contained"
            size="small"            
          >
            <LocalPhoneIcon />
            <Divider className={classes.divider} />
            Book Now
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
